<template>
	<div class="mo-header flex-center" v-if="isMobileLayout">
		<div class="flex-center">
			<a href="/">
				<img src="@/assets/logo/index.png" alt="logo" style="width: 40px;">
			</a>
		</div>
		<div class="header-btns flex-center" style="width:calc(100% - 80px);">
			<div class="search-box">
				<input class="search-input" placeholder="Search" type="text" v-model="searchText">
				<!-- <img class="search-icon" src="@/assets/img/search-mo.svg" alt=""> -->
				<search-icon class="search-icon"></search-icon>
				<ul v-if="!showMobileCategories" class="search-item-box" :style="customStyle">
					<li v-for="game in searchRusult" :key="game.handle">
						<a class="search-item" :href="'/game/' + game.handle">{{game.title}}</a>
					</li>
				</ul>
			</div>
		</div>
		<div class="mo-fav-play flex-center">
			<div @click="showMobileCategories=!showMobileCategories">
				<!-- <img src="@/assets/img/menu.svg" alt="menu" width="25"> -->
				<menu-mo class="menu-mo"></menu-mo>
			</div>
			<ul v-if="showMobileCategories" class="search-item-box">
				<li v-for="item in uniqueCategories" :key="item.category_handle">
					<a class="search-item" :href="'/category/' + item.category_handle">{{item.category}}</a>
				</li>
			</ul>
		</div>
	</div>
	<div class="header flex-center" v-else>
		<div class="flex-center">
			<a href="/">
				<img class="header-logo-img" src="@/assets/logo/logo.png" alt="logo">
			</a>
		</div>
		<div style="flex: 1 1 0%;"></div>
		<div class="header-class flex-center">
			<a v-for="item in uniqueCategories" class="header-center btn-big-color"
				:href="'/category/' + item.category_handle" :key="item.cateogory_handle">
				<div style="white-space: nowrap;">{{ item.category }} </div>
			</a>
		</div>
		<div class="header-btns flex-center">
			<div class="search-box">
				<input class="search-input" placeholder="Search" type="text" v-model="searchText">
				<!-- <img class="search-icon" src="@/assets/img/search.svg" alt=""> -->
				<search-icon class="search-icon"></search-icon>
				<ul class="search-item-box" :style="customStyle">
					<li v-for="game in searchRusult" :key="game.handle">
						<a class="search-item" :href="'/game/' + game.handle">{{game.title}}</a>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
	import SearchIcon from '@/components/common/search-icon'
	import MenuMo from '@/components/common/menu-mo'
	import {
		mapGetters,
		mapState
	} from 'vuex'
	export default {
		inject: ['app'],
		components: {
			SearchIcon,
			MenuMo
		},
		computed: {
			...mapGetters({
				uniqueCategories: 'games/uniqueCategories',
			}),
			...mapState({
				games: state => state.games.games
			}),
			searchRusult() {
				// 将 searchText 转换为小写并去除空格
				const normalizedSearchText = this.searchText.toLowerCase().replace(/\s+/g, '')
				if (normalizedSearchText === '') return []
				return this.games.filter(game => game.title.toLowerCase().replace(/\s+/g, '').includes(
					normalizedSearchText))
			},
			customStyle() {
				return this.searchRusult.length > 0 ? '' : 'display:none;'
			},
			isMobileLayout() {
				return this.app.isMobileLayout
			}
		},
		data() {
			return {
				searchText: '',
				showMobileCategories: false
			}
		}
	}
</script>

<style scoped>
	.header {
		width: 100%;
		box-shadow: 0 0 10px rgba(0,0,0,.4);
		padding-left: 60px;
	}

	.mo-header {
		width: 100%;
		height: 50px;
    	text-align: center;
		padding: 0 10px;
		position: relative;
	}

	.header-logo-img {
		width: 130px;
	    -o-object-fit: contain;
	    object-fit: contain;
	    -o-object-position: center;
	    object-position: center;
	}

	.header-class {
		font-weight: 500;
		font-size: 15px;
		overflow: hidden;
		width: 60%;
		overflow-x: auto;
		-webkit-box-pack: end;
		-webkit-justify-content: end;
		-moz-box-pack: end;
		justify-content: end;
	}

	.header-btns {
		/* height: 100%; */
		height: 50px;
		width: 300px;
	}

	.search-box {
		position: relative;
		-webkit-box-flex: 1;
		-webkit-flex: 1;
		-moz-box-flex: 1;
		flex: 1;
		max-width: 350px;
		margin: 0 20px;
	}

	.search-input {
		width: 100%;
		height: 30px;
		padding: 0 20px;
		padding-right: 35px;
		border: none;
		border-radius: 5px;
		outline: none;
		font-weight: 600;
		font-size: 15px;
	}

	.search-icon {
		position: absolute;
		top: 50%;
		right: 10px;
		width: 20px;
		height: 20px;
		-webkit-transform: translateY(-50%);
		transform: translateY(-50%);
	}

	.search-item-box {
		position: absolute;
		z-index: 5;
		top: 100%;
		left: 10px;
		right: 10px;
		margin: 0;
		margin-top: 4px;
		padding: 10px 0;
		border-radius: 4px;
		box-shadow: 0 2px 8px #a9a9a9;
		color: #000;
		background-color: #fff;
		list-style-type: none;
	}

	.search-item {
		display: block;
		padding: 5px 15px;
	}

	.mo-fav-play {
	    -webkit-justify-content: space-around;
	    justify-content: space-around;
		width: 40px;
	}

	.header-center {
		margin: 0 8px;
		display: -webkit-inline-box;
		display: -webkit-inline-flex;
		display: -moz-inline-box;
		display: inline-flex;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-moz-box-align: center;
		align-items: center;
		padding: 4px 10px;
		border-radius: 999px;
	}
	
	.menu-mo {
		width: 31px;
		height: 31px;
	    padding: 2px;
		border-style: solid;
		border-width: 1px;
	}
</style>
<style lang="scss" scoped>
	.header {
		background-image: -webkit-gradient(linear,left top,left bottom,from($bg-h-top),to($bg-h-bottom));
		background-image: -webkit-linear-gradient($bg-h-top,$bg-h-bottom);
		background-image: linear-gradient($bg-h-top,$bg-h-bottom);
	}

	.mo-header {
		background-image: -webkit-gradient(linear,left top,left bottom,from($bg-h-top),to($bg-h-bottom));
		background-image: -webkit-linear-gradient($bg-h-top,$bg-h-bottom);
		background-image: linear-gradient($bg-h-top,$bg-h-bottom);
	}

	.search-input {
		border: 1px solid $input-border;
		background-color: $input-bg;
	}
</style>