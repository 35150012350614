<template>
	<div class="footer-container">
		<div v-if="isMobileLayout" class="footer">
			<div>
				<a href="/pages/about">About Us</a> | <a href="/pages/terms">Terms of Use</a> | <a href="/pages/privacy">Privacy</a> | <a href="/pages/contact">Contact Us</a>
			</div>
			<div class="copyright"><p>Copyright © 2024 All rights Reserved</p></div>
		</div>
		<div v-else class="footer footer-pc">
			<p style="margin-right: 20px;">Copyright © 2024 All rights Reserved</p>
			<a href="/pages/about">About Us</a> | <a href="/pages/terms">Terms of Use</a> | <a href="/pages/privacy">Privacy</a> | <a href="/pages/contact">Contact Us</a>
		</div>
	</div>
</template>

<script>
	export default {
		inject: ['app'],
		computed: {
			isMobileLayout() {
				return this.app.isMobileLayout
			}
		}
	}
</script>
<style scoped>
	.footer-container {
		margin-top: 20px;
	}

	.footer {
	    overflow: hidden;
	    padding: 10px;
	    font-size: 9px;
	    text-align: center;
	    bottom: 0;
	    width: 100%;
	}

	.footer-pc {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	
	.footer a {
	    margin: 0 8px;
	    line-height: 20px;
	}
	
	.copyright {
	    font-size: 10px;
	    text-align: center;
	}
	
	.copyright p {
	    margin: 0;
	    line-height: 1.5;
	}
</style>
<style lang="scss" scoped>
	.footer {
		color: $txt-foot-color;
		background-image: -webkit-gradient(linear,left top,left bottom,from($bg-f-top),to($bg-btn-bottom));
		background-image: -webkit-linear-gradient($bg-f-top,$bg-f-bottom);
		background-image: linear-gradient($bg-f-top,$bg-f-bottom);
	}
</style>